@footerHeight: 180px;

#site_content {
  margin-top: 20px;
}

.mainstartbutton {
    margin-top: 20px;
}

  .logo {
    img {
      height: 50px;
      width: 50px;
      padding: 5px;
    }

    .logo-img-box {
      display: inline-block;
    }

    .logo-text-box {
      margin-left: 20px;
      font-size: 20px;
      display: inline-block;
      .a {
        color: white;
      }

      @media screen and (max-width: @screen-xs-max) {
        font-size: 14px;
      }
    }

    div {
      display: inline-block;
      vertical-align: middle;
    }
  }

footer {
  background: black;
  position: relative;
  //.box-sizing(border-box);

  height: @footerHeight;
  //margin-top: -@footerHeight;
  margin-top: 20px;

  clear: both;

  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.2em;
  font-variant: small-caps;
  color: white !important;

  a, a:hover {
    color: white;
  }

  .sep {
    color: darken(white, 35%);
  }

  .address {
    margin-bottom: 4px;
  }


  .footer-content {
    background: black;
    margin: 0;
    padding: 0;
    padding-top: 50px;


    .org {
      width: 48%;
      margin-right: 1%;
      float: left;
      text-align: right;
    }

    .col2 {
      max-width: 48%;
      margin-left: 1%;
      padding-left: 1%;
      border-left: 1px solid darken(white, 35%);
      float: left;
      text-align: left;
      font-size: 92%;
      color: darken(white, 10%);

      .email {
        font-variant: normal;
        letter-spacing: 0;
        font-family: verdana;
        line-height: 200%;
      }
    }

    .vertical-align {
      display: flex;
      align-items: center;
    }

    .info-list {
      list-style-type: none;
    }
  }

  .legal {
    font-size: 80%;
    position: absolute;
    left: 14px;
    bottom: 14px;

    a {
      margin: 14px;
    }
  }
}

.panelinfo {
  .panel {
    .item-group {
      margin-top: 15px;
    }

    .item {
      clear: both;
      min-height: 1px;
      border-bottom: 1px dotted #999;
      overflow: hidden;
    }

    .panel-heading .item {
      border-bottom: none;
    }

    .item {
      margin-top: 3px;
    }

    .key {
      float: right;
      font-size: 85%;
      font-style: italic;
      color: #999;
      padding-right: 1ex;
    }

    .value {
    }
  }

  .panel > .key,
  .panel > .value {
    float: none;
    width: 100%;
    max-width: 100%;
  }

  .panel > .key::after {
    content: none;
  }
}

.DataFilter .filtertitle {
  font-size: 14px;
  font-weight: bold;
}
