
@import "theme-less-vars";

.card-page {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

:root {
    --qr-size: 280px;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.phone {
    position: relative;

    #phone-img {
      @media screen and (max-width: 600px) {
          visibility: hidden;
      }
    }

    .phone-content {
        height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        border: 2px solid #00205b;

        @media screen and (max-width: 600px) {
          border: none;
        }

        hgroup {
            display: flex;
            align-items: center;
            max-width: 100vw;
            gap: 20px;
            padding-inline: max(4rem, 25vw);
            background-color: #00205b;
            color: white;
            padding:  1.5rem 2rem;
            border: 1px solid green;

            .header {
                display: flex;
                align-items: center;
                gap: 1rem;
                img {
                    width: 60px;
                    aspect-ratio: 1;
                }
            }

            h1 {
                font-size: clamp(1rem, 6vw, 2rem);
                margin: 0;
            }


        }
        hgroup:hover {
            background-color: #00205bbe;
        }

        .qr-wrapper {
            margin: 0 auto;
            display: block;
            position: relative;

            p {
                text-align: center;
                font-size: .8rem;
                margin-top: 0;
            }
        }

        #nff-logo {
            position: absolute;
            top: calc(var(--qr-size) / 2);
            left: calc(var(--qr-size) / 2);
            transform: translate(-50%, -50%);
            width: 90px;
            aspect-ratio: 1;
        }

        em {
            font-style: normal;
            font-size: 120%;
            margin-inline: 1rem;
        }

        .content {
            text-align: center;
            padding-inline: 2rem;
            h2 {
                margin-bottom: 0;
            }

            p.dommernr {
                margin: 0;
                color: #666;
            }
        }

        .valid {
            color: green;
            font-size: 1.5rem;
            text-align: center;

        }

        footer {
            padding-block: clamp(5px, 3vw, 2rem) clamp(5px, 5vw, 2rem);
            padding-inline: clamp(5px, 5vw, 2rem);
            background-color: white!important;
            color: black!important;
            font-variant: none!important;
        }
    }
}
