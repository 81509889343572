.personinfo {
    .item-group {
        margin-top: 15px;
    }
    .item {
        clear: both;
        min-height: 1px;
        border-bottom: 1px dotted #999;
        overflow: hidden;
    }

    .panel-heading .item {
        border-bottom: none;
    }

    .item {
        margin-top: 3px;
    }

    .key {
        float: right;
        font-size: 85%;
        font-style: italic;
        color: #999;
        padding-right: 1ex;
    }
    .value {
    }

    .panel > .key,
    .panel > .value {
        float: none;
        width: 100%;
        max-width: 100%;
    }

    .panel > .key::after {
        content: none;
    }
}

.password {
    #new-password-form {
        margin-top: 10px;
        .input-group {
            width: 100%;
        }
        .form-group {
            width: 100%;
        }
    }
    #send-password-form {
        margin-top: 10px;
    }
}
