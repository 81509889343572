.fotball-ok {
  color: #1a660d;
}

.fotball-fail {
  color: #FF0000;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.navbar-brand {
  width: 300px !important;
  border-right: 1px solid white;
}

body {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
}


.center-box {
  flex-grow: 1;
  display: flex;

  & > main {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 30px;
    overflow: auto;
  }

  & > .left-nav {
    background-color: #555;
    color: #efefef;
    width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > .left-nav li {
      border-bottom: 1px solid #999;
    }

    a {
      color: #efefef !important;
    }

    .btn-default {
      background-color: #555;
    }
  }
}

nav.left-nav {
  .left-nav-content {
    padding: 0 14px 40px;
    overflow: auto;

    .toolbar-section > a {
      display: block;
    }

    menu[type=toolbar] {
      margin: 14px 0 0 0;
      padding: 0;

      [type=menu] {
        cursor: pointer;
        margin-bottom: 5px;

        small {
          color: #999;
        }

        a {
          outline: none;
        }

        &:hover,
        &.selected {
          background-color: #353535;
          margin-left: -14px;
          margin-right: -14px;
          padding-left: 14px;
        }

        &.selected {
          background-color: black;
          border: 3px solid black;
          border-right-width: 0;
          border-left-width: 0;

          &:after {
            display: block;
            border: 12px solid transparent;
            border-right-color: white;
            float: right;
            position: relative;
            top: -1px;
            width: 0;
            height: 0;
            z-index: 2;
            content: "";
          }
        }

      }

      .home-menu-item.selected:after {
        border-width: 16px;
      }
    }

    h3 {
      color: #999;
      font-size: 1.5rem;
      margin: 12px 0 5px;
    }

    a.pbtn {
      border-color: #999;
      border-radius: 5px;

      & > dk-icon,
      & > span.title {
        color: #999;
      }

      &:visited {
        color: #666;
      }

      &:hover {
        border-color: #bbb;

        & > span.title {
          color: #bbb;
        }

        &:visited {
          color: #bbb;
        }
      }
    }

    .person {
      .plabel {
        font-size: 80%;
        color: #ccc;
      }

      .pvalue {
        line-height: 100%;
      }

      .img-logo {
        max-height: 80px;
        display: block;
        margin: 20px 20px 20px 0;
      }

      .person-main-info {
        display: flex;
        flex-direction: row;
        align-items: center;

        .person-demographics {
          display: flex;
          flex-direction: column;

          .person-info {
            margin-bottom: 1rem;
          }
        }
      }

      .person-secondary-info {
        font-size: 80%;

        .edit-person-info {
          visibility: hidden;
        }

        &:hover {
          .edit-person-info {
            visibility: visible;
          }
        }
      }
    }
  }
}

dk-panel {
  .panel-body {
    overflow: hidden;
  }

  footer.panel-footer {
    display: none;
  }
}

a.dashboard-btn {
  white-space: normal;
  width: 120px;
  height: 110px;
  margin: 0 4px 4px 0;
}

.documents {
  color: #888;
}

.documents .dashboard-btn {
  width: auto;
  height: auto;
}

.dashadmin .dashboard-btn {
  padding: 20px;
}

dk-panel > .panel-heading .headingtext {
  font-size: 14px !important;
}

.second.row.information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1325px) {
    flex-direction: row;
    .calendar {
      width: 45%;
    }

    .documents {
      width: 45%;
    }
  }
}

.documents {
  h4 {
    display: inline;
  }
}

//@stack-img-size: 38px;
@stack-img-size: 41px;
@stack-middle: (@stack-img-size / 2);
@stack-ikn-size: (@stack-img-size / 2.7777);
@stack-ikn-pos: (@stack-middle - @stack-ikn-size / 2);

.documentation-btn {
  width: 100%;
  margin-bottom: 4px;
  text-align: left;
}

.stack {
  position: relative;
  display: inline-block;

  img {
    width: @stack-img-size;
    height: @stack-img-size;
  }

  dk-icon {
    position: absolute;
    top: @stack-ikn-pos;
    left: (@stack-ikn-pos - 1);
    font-size: @stack-ikn-size;
    line-height: 100%;
  }
}

.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border) {
  border-color: @border;

  & > .panel-heading {
    color: @heading-text-color;
    background-color: @heading-bg-color;
    border-color: @heading-border;

    + .panel-collapse > .panel-body {
      border-top-color: @border;
    }

    .badge {
      color: @heading-bg-color;
      background-color: @heading-text-color;
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      border-bottom-color: @border;
    }
  }
}

.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border) {
  border-color: @border;

  & > .panel-heading {
    color: @heading-text-color;
    background-color: @heading-bg-color;
    border-color: @heading-border;

    + .panel-collapse > .panel-body {
      border-top-color: @border;
    }

    .badge {
      color: @heading-bg-color;
      background-color: @heading-text-color;
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      border-bottom-color: @border;
    }
  }
}

@staff-text: white;
@staff-bg: red;
@staff-border: darken(spin(@staff-bg, -10), 5%);

.panel-admin {
  .panel-variant(@staff-border, @staff-text, @staff-bg, @staff-border)
}

@tl-text: white;
@tl-bg: orange;
@tl-border: darken(spin(@tl-bg, -10), 5%);

.panel-tl {
  .panel-variant(@tl-border, @tl-text, @tl-bg, @tl-border)
}

@ka-text: #333;
@ka-bg: desaturate(lighten(green, 45%), 75%);
@ka-border: darken(spin(@ka-bg, -10), 5%);

.panel-ka {
  .panel-variant(@ka-border, @ka-text, @ka-bg, @ka-border)
}
