
@import "theme-less-vars";

.modal-page {
    min-height: auto!important;
    height: auto!important;

    h1 {
        font-size: 36px!important;
    }

    &.ok {background-color: @base-color;}
    &.danger {background-color: @base-color;}
    &.error {background-color: @error-color;}
    &.warning {background-color: @warning-color;}
    &.info {background-color: @info-color;}

    .modal-box {
        @padding: 60px;
        display: flex;
        position: relative;
        flex-direction: column;
        align-content: space-between;
        //border: 1px solid #999;
        //border-radius: 6px;
        background-color: @background-color;

        margin-top: 5%;
        max-width: 800px;
        padding: @padding;
        min-height: 400px;

        @media screen and (max-width: @screen-xs-max) {
            @margin: 1px;
            max-width: calc(100% - 2 * @margin);
            max-height: calc(100% - 2 * @margin);
            margin-top: @margin;
            padding: 10px;

            //header .logo {
            //    max-width: 400px;
            //}
        }

        > header {
            //border-bottom: 1px solid @neutral-color-20;
            //padding-bottom: @gutter-width;
            //margin-bottom: @gutter-width;
            .logo {
                max-width: 180px;
            }
        }

        > footer {
            hr {
                width: 30%;
                text-align: left;
            }
        }
    }
}
